import { lazy, Suspense } from "react";

import { RouterWrapper } from "@bees-grow-shared/business-components";
import { Card } from "@hexa-ui/components";

import * as Styled from "./App.styles";
import { LoadingBehavior as ChallengeCardsLoadingBehavior } from "./components/clubB/components/clubBTabs/components/pointsBasedOffers/components/challengeCards/ChallengeCards";
import { EnvProvider } from "./components/envProvider/EnvProvider";
import { EnvConfig } from "./components/envProvider/EnvProvider.types";

const OptimizelyWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.OptimizelyWrapper,
  })),
);

const AnalyticsWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.AnalyticsWrapper,
  })),
);

const UserConfigWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.UserConfigWrapper,
  })),
);

const ThemeProvider = lazy(() =>
  import("@bees-grow-shared/theme").then((module) => ({
    default: module.ThemeProvider,
  })),
);

const IntlProvider = lazy(() =>
  import("@/i18n").then((module) => ({ default: module.IntlProvider })),
);

const LeveragePage = lazy(() => import("@/pages/leveragePage/LeveragePage"));

export default function App(props: Readonly<EnvConfig>) {
  return (
    <EnvProvider env={props}>
      <Suspense fallback={<LoadingBehavior />}>
        <ThemeProvider>
          <UserConfigWrapper>
            <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY}>
              <AnalyticsWrapper segmentKey={props.SEGMENT_KEY}>
                <IntlProvider>
                  <RouterWrapper>
                    <LeveragePage />
                  </RouterWrapper>
                </IntlProvider>
              </AnalyticsWrapper>
            </OptimizelyWrapper>
          </UserConfigWrapper>
        </ThemeProvider>
      </Suspense>
    </EnvProvider>
  );
}

function LoadingBehavior() {
  return (
    <Styled.LoadingContainer>
      <Styled.LoadingContainerClubB>
        <Styled.LoadingClubBHeader />
        <Card elevated="small" border="medium">
          <Styled.LoadingContainerCard>
            <Styled.LoadingClubBTabs />
            <Styled.LoadingContainerContent>
              <Styled.LoadingContainerOverviewStatuses>
                <Styled.LoadingContainerOverview>
                  <Styled.LoadingClubBOverviewTitle />
                  <Styled.LoadingClubBOverviewDescription />
                </Styled.LoadingContainerOverview>
                <Styled.LoadingClubBStatuses />
              </Styled.LoadingContainerOverviewStatuses>
              <Styled.LoadingContainerRewardCards>
                <ChallengeCardsLoadingBehavior />
              </Styled.LoadingContainerRewardCards>
            </Styled.LoadingContainerContent>
          </Styled.LoadingContainerCard>
        </Card>
      </Styled.LoadingContainerClubB>
    </Styled.LoadingContainer>
  );
}
