import { Card } from "@hexa-ui/components";

import * as Styled from "./ChallengeCards.styles";

export default function ChallengeCards() {
  return <LoadingBehavior />;
}

export function LoadingBehavior() {
  const LoadingBehaviorChallengeCard = () => (
    <Card elevated="minimal" border="small">
      <Styled.LoadingChallengeCardImage variant="image" />
      <Styled.LoadingChallengeCardContainer>
        <Styled.LoadingChallengeCardContainerTitle>
          <Styled.LoadingChallengeCardTitleTop />
          <Styled.LoadingChallengeCardTitleBottom />
        </Styled.LoadingChallengeCardContainerTitle>
        <Styled.LoadingChallengeCardContainerTags>
          <Styled.LoadingChallengeCardTagFirst />
          <Styled.LoadingChallengeCardTagSecond />
        </Styled.LoadingChallengeCardContainerTags>
      </Styled.LoadingChallengeCardContainer>
    </Card>
  );

  return (
    <Styled.LoadingContainer data-testid="challenge-cards-loading">
      {[...Array(4)].map((_, i) => (
        <LoadingBehaviorChallengeCard key={i} />
      ))}
    </Styled.LoadingContainer>
  );
}
