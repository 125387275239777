import { tokens } from "@bees-grow-shared/theme";
import { SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

import { Container as ClubBContainer } from "./components/clubB/ClubB.styles";
import { Container as ClubBTabsContainer } from "./components/clubB/components/clubBTabs/ClubBTabs.styles";
import {
  OverviewContainer,
  OverviewStatusesContainer,
  Container as PointsBasedOffersContainer,
} from "./components/clubB/components/clubBTabs/components/pointsBasedOffers/PointsBasedOffers.styles";
import { Container as LeveragePageContainer } from "./pages/leveragePage/LeveragePage.styles";

export const LoadingContainer = LeveragePageContainer;

export const LoadingContainerClubB = ClubBContainer;

export const LoadingContainerCard = ClubBTabsContainer;

export const LoadingContainerContent = PointsBasedOffersContainer;

export const LoadingContainerOverviewStatuses = OverviewStatusesContainer;

export const LoadingContainerOverview = OverviewContainer;

export const LoadingContainerRewardCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.measure.space["spacing-4"]};
`;

export const LoadingClubBHeader = styled(SkeletonLoader)`
  width: 4.1875rem;
  height: 2rem;
`;

export const LoadingClubBTabs = styled(SkeletonLoader)`
  width: 100%;
  height: 3.25rem;
`;

export const LoadingClubBOverviewTitle = styled(SkeletonLoader)`
  width: 4.5625rem;
  height: 1.5rem;
`;

export const LoadingClubBOverviewDescription = styled(SkeletonLoader)`
  width: 20.1875rem;
  height: 1.25rem;
`;

export const LoadingClubBStatuses = styled(SkeletonLoader)`
  width: 48.4375rem;
  height: 3.25rem;
`;
